/* eslint-disable max-lines */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import lodash from 'lodash';

import Title from 'components/common/Title';
import { UseStylingButton, UseStylingDropZoneButton } from 'hooks/useStylingForMaterialUi';
import useCompany, { useCompanyType } from 'hooks/api/useCompany';
import useActivationOrder, { AoTypeEnum, useActivationOrderType } from 'hooks/api/useActivationOrder';
import { getToken } from 'helpers/token';
import { convertFileToBase64 } from 'helpers/file';
import { formatDateIsoShort, getCurrencyWithAmount } from 'helpers/formatters';
import useContract, { useContractType } from 'hooks/api/useContract';
import { bodyFutureReceivables } from 'queries/activationOrder/createFutureReceivables';
import { bodyAvailableReceivables } from 'queries/activationOrder/createAvailableReceivables';
import urls from 'constants/urls';
import endpoints from 'consts/endpoints';
import useAlert from 'hooks/api/useAlert';
import useErrorCallback from 'hooks/useErrorCallback';
import { fieldTranslate, fileAODataType, listIsOpenData } from 'components/forms/ActivationOrderForm/data';
import ModalConfirm from 'components/ModalConfirm';
import { getFactorCompany } from 'api/company';
import { CompanyKeyEnum } from 'types/CompanyTypes';

import {
  initialDistributionPlatform,
  initialOrderCardsData,
  KeyCardsEnum,
  MAP_TYPE_AO_TO_TYPE_FILE,
  StatusEnumAo,
  statusParamsEnum,
  switchListInitial,
} from './CardsAo/data';
import CardClassic from './CardsAo/CardClassic/CardClassic';
import CardApplaud from './CardsAo/CardApplaud/CardApplaud';
import CardAvailableReceivables from './CardsAo/CardAvailableReceivables/CardAvailableReceivables';
import CardNow from './CardsAo/CardNow/CardNow';
import CardFutureReceivables from './CardsAo/CardFutureReceivables/CardFutureReceivables';
import useErrors from './CardsAo/useErrors';
import useCurrentRequiredKeyOfCard from './CardsAo/useCurrentRequiredKeyOfCard';
import ItemsAo from './CardsAo/ItemsAo';
import CreateTransactionPopUp from './PopUps/CreateTransactionPopUp/CreateTransactionPopUp';

export interface paramsObjAO {
  status?: statusParamsEnum;
  type?: AoTypeEnum;
  companyId?: string;
  activationOrderId?: string;
}

interface ActivationOrderFormType {
  requestParams?: {
    params?: paramsObjAO;
    requestParams?: object;
  };
}

const ActivationOrderForm: FC<ActivationOrderFormType> = React.memo(({ requestParams: { params } }) => {
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [factorAccounts, setFactorAccounts] = useState([]);
  const { status, type, activationOrderId } = params;
  const paramsUrl = useParams();

  const { t } = useTranslation();
  const { getCompanies, aoFormData, setAOFormData, listSwitch, setIsSwitchList, companiesData }: useCompanyType =
    useCompany();
  const { clearContractsData, getContracts }: useContractType = useContract();

  const stylingButton = UseStylingButton();
  const dropZoneStylingButton = UseStylingDropZoneButton();
  const {
    getActivationOrder,
    activationOrderData,
    clearActivationOrder,
    createActivationOrder,
    createActivationOrderApplaud,
    editActivationOrderApplaud,
    editActivationOrder,
    createAvailableReceivables,
    createFutureReceivables,
    editAvailableReceivables,
    editFutureReceivables,
    getAvailableReceivables,
    getFutureReceivables,
    createScheduleForFutureReceivables,
    editScheduleForFutureReceivables,
    schedulesFutureReceivablesData,
    getScheduleForFutureReceivables,
  }: useActivationOrderType = useActivationOrder();
  const { errors, setErrors, changeErrors, getErrors, addErrors } = useErrors();
  const [listIsOpen, setListIsOpen] = useState(listIsOpenData);
  const { listRequired, errorsList } = useCurrentRequiredKeyOfCard({
    isOpenClosingDatePopUp: !!aoFormData[KeyCardsEnum.TX_DATE],
  });
  const { setAlert } = useAlert();
  const history = useHistory();
  const [isPending, setIsPending] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const successCallback = useCallback(
    (actionType) => {
      setIsPending(false);

      if (actionType.name === 'Cancel' && status === statusParamsEnum.CREATE) {
        history.push(urls.getActivationOrder());

        return;
      }
      history.push(
        `${urls.getActivationOrder()}/${actionType?.id || activationOrderId || ''}/?type=${
          aoFormData?.ao_type || type
        }`,
      );
      let actionName;

      if (status === statusParamsEnum.EDIT && actionType.deleted) {
        actionName = t('global.deleted');
      }

      if (status === statusParamsEnum.EDIT && !actionType.deleted) {
        actionName = t('global.updated');
      }

      if (status !== statusParamsEnum.EDIT) {
        actionName = t('global.created');
      }

      if (status === statusParamsEnum.EDIT && actionType?.name) {
        actionName = actionType?.name;
      }
      setAlert({
        type: 'success',
        message: `${t('alert.activationOrder')} ${actionName}`,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, setAlert, t, type, aoFormData],
  );

  const { errorCallback } = useErrorCallback(({ errorField, errorCallbackText, customParams }) => {
    setIsPending(false);

    if (errorField === KeyCardsEnum.TX_DATE) {
      setAOFormData({ ...customParams.aoFormData, [KeyCardsEnum.TX_DATE]: errorCallbackText });
      setListIsOpen({ ...listIsOpen, [KeyCardsEnum.TX_DATE]: true });

      return false;
    }

    if (Object.keys(errors).includes(fieldTranslate(errorField))) {
      setErrors('', fieldTranslate(errorField), { required: true });

      return false;
    }

    return true;
  });

  const setFileAO = async (body: fileAODataType) => {
    try {
      const res = await fetch(endpoints.getCreateActivationOrderDocumentUrl(), {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(body),
      });
      const statusCode = await res;

      if (!statusCode.ok) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (await res.json()) as Promise<any>;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (await res.json()) as Promise<any>;
    } catch (iserror) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return iserror as any;
    }
  };

  const currentFactorAccount =
    factorAccounts.find((bankAccount) => bankAccount.currency === activationOrderData.currency) || {};

  const onSubmit = useCallback(
    async (isModal?: boolean, isPopUp?: boolean) => {
      changeErrors({});
      const settingKeys = Object.keys(listSwitch).filter(
        (item) => item !== KeyCardsEnum.CUSTOM_SETTINGS && listSwitch[item],
      );
      const commonCheckKeys = [...settingKeys, ...listRequired];

      Object.keys(errorsList).forEach((objKey) => {
        if (objKey === KeyCardsEnum.ROOT) {
          errorsList[objKey].forEach(({ key, parameters }) => {
            if (commonCheckKeys.find((item) => key === item)) {
              setErrors(aoFormData[key], key, parameters);
            }
          });
        }

        if (
          objKey !== KeyCardsEnum.ROOT &&
          Array.isArray(aoFormData[objKey]) &&
          commonCheckKeys.find((item) => objKey === item)
        ) {
          errorsList[objKey].forEach(({ key, parameters }) => {
            aoFormData[objKey].forEach((itemLocal, i) => {
              Object.keys(itemLocal).forEach((keyLocal) => {
                if (keyLocal === key) {
                  setErrors(aoFormData[objKey][i][key], `${keyLocal}_${i}`, parameters);
                }
              });
            });
          });
        }
      });
      const errorsLocal = getErrors();

      // TODO revert
      if (!lodash.isEmpty(errorsLocal)) return;

      if (!isModal && status === statusParamsEnum.EDIT) {
        setIsShowModal(true);

        return;
      }
      let obj = {};

      if (isPopUp) {
        obj = { ...obj, sender: currentFactorAccount };
      }

      if (!aoFormData.checkbox && isPopUp) {
        obj = { ...obj, recipient: { company_id: aoFormData.recipient } };
      }

      if (aoFormData.mfa_usd?.toString()?.length > 0) {
        obj = { ...obj, mfa_usd: aoFormData.mfa_usd };
      }

      if (aoFormData.mfa_eur?.toString()?.length > 0) {
        obj = { ...obj, mfa_eur: aoFormData.mfa_eur };
      }

      commonCheckKeys.forEach((key) => {
        if (aoFormData[key]) {
          obj = { ...obj, [key]: aoFormData[key] };
        }
      });

      if (aoFormData[KeyCardsEnum.DROPZONE].length > 0 && MAP_TYPE_AO_TO_TYPE_FILE[aoFormData.ao_type]) {
        const isNewFile = aoFormData[KeyCardsEnum.DROPZONE][0].size;

        if (isNewFile) {
          const fileUrl: string = (await convertFileToBase64(
            aoFormData[KeyCardsEnum.DROPZONE][0],
          )) as unknown as string;
          const result = await setFileAO({
            company_id: aoFormData[KeyCardsEnum.COMPANY_ID] || '',
            file_data: fileUrl,
            name: aoFormData[KeyCardsEnum.NAME],
            number: aoFormData[KeyCardsEnum.NAME],
            type: MAP_TYPE_AO_TO_TYPE_FILE[aoFormData.ao_type],
            date: formatDateIsoShort(new Date()),
          });

          obj = { ...obj, ao_document_id: result.activation_order_document.id };
        }

        if (!isNewFile) {
          obj = { ...obj, ao_document_id: activationOrderData.ao_document_id };
        }
      }

      if (aoFormData.ao_type === AoTypeEnum.CLASSIC) {
        status === statusParamsEnum.CREATE && createActivationOrder({ body: obj, successCallback, errorCallback });
        status === statusParamsEnum.EDIT &&
          editActivationOrder({ body: obj, id: activationOrderId, successCallback, errorCallback });
      }

      if (aoFormData.ao_type === AoTypeEnum.APPLAUD_FIX) {
        status === statusParamsEnum.CREATE &&
          createActivationOrderApplaud({ body: obj, successCallback, errorCallback });
        status === statusParamsEnum.EDIT &&
          editActivationOrderApplaud({ body: obj, id: activationOrderId, successCallback, errorCallback });
      }

      if (aoFormData.ao_type === AoTypeEnum.AVAILABLE_RECEIVABLES) {
        const rawResult = await fetch(
          `${process.env.REACT_APP_BASE_URL_V2}/contracts/${paramsUrl?.activationOrderId}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          },
        );
        const resultJSON = await rawResult.json();
        const contractId = resultJSON?.contracts[0]?.id;

        if (!contractId) {
          setAlert({
            type: 'error',
            message: `Document Id not found.`,
          });
          return;
        }

        if (status === statusParamsEnum.CREATE) {
          const rawResult2 = await fetch(
            `${process.env.REACT_APP_BASE_URL}/next_ao_number?company_id=${paramsUrl?.activationOrderId}&document_id=${contractId}`,
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${getToken()}`,
              },
            },
          );
          const resultJSON2 = await rawResult2.json();
          const nextAoNumber = resultJSON2?.next_ao_number;

          if (!nextAoNumber) {
            setAlert({
              type: 'error',
              message: `Can't get next AO number.`,
            });
            return;
          }

          obj.name = nextAoNumber;
        }

        obj.company_id = paramsUrl?.activationOrderId;
        obj.contract_id = contractId;

        status === statusParamsEnum.CREATE &&
          createAvailableReceivables({
            body: obj as bodyAvailableReceivables,
            successCallback,
            errorCallback: (err) => {
              setAlert({
                type: 'error',
                message: JSON.stringify(err?.body?.description),
              });
            },
          });

        status === statusParamsEnum.EDIT &&
          editAvailableReceivables({
            body: obj as bodyAvailableReceivables,
            id: activationOrderId,
            successCallback,
            errorCallback,
          });
      }

      if (aoFormData.ao_type === AoTypeEnum.FUTURE_RECEIVABLES) {
        if (
          Number(aoFormData?.purchased_receivables) !==
          aoFormData?.schedules?.reduce((prev, { amount }) => Number(amount || 0) + prev, 0)
        ) {
          addErrors({
            [KeyCardsEnum.PURCHASED_RECEIVABLES]: 'Total Amount is not equal Purchased Receivables',
            [KeyCardsEnum.PAYMENT_PLAN]: 'Total Amount is not equal Purchased Receivables',
          });

          return;
        }

        status === statusParamsEnum.CREATE &&
          createFutureReceivables({
            body: obj as bodyFutureReceivables,
            successCallback: async (activationType) => {
              await createScheduleForFutureReceivables({
                body: { order_id: activationType.id, schedules: aoFormData[KeyCardsEnum.PAYMENT_PLAN] },
                errorCallback,
              });
              successCallback(activationType);
            },
            errorCallback,
          });

        if (status === statusParamsEnum.EDIT) {
          setIsSubmittingForm(true);

          editFutureReceivables({
            body: obj as bodyFutureReceivables,
            id: activationOrderId,
            successCallback: (activationType) => {
              const newFields = aoFormData[KeyCardsEnum.PAYMENT_PLAN].filter(
                ({ id, amount, payment_date }) => !id && amount && payment_date,
              );
              const changedFields = aoFormData[KeyCardsEnum.PAYMENT_PLAN].filter((item) => {
                const fundedField = schedulesFutureReceivablesData.records.find(
                  ({ id }) => id === item[KeyCardsEnum.PAYMENT_ID],
                );

                if (!fundedField) return false;

                return !lodash.isEqual(item, fundedField);
              });

              newFields.length > 0 &&
                createScheduleForFutureReceivables({
                  body: { order_id: activationType.id, schedules: newFields },
                  errorCallback,
                });

              changedFields.length > 0 &&
                editScheduleForFutureReceivables({
                  body: {
                    order_id: activationType.id,
                    [KeyCardsEnum.PURCHASED_RECEIVABLES]: aoFormData[KeyCardsEnum.PURCHASED_RECEIVABLES],
                    schedules: changedFields.map(({ id, amount, deleted, payment_date }) => ({
                      id,
                      amount,
                      deleted,
                      payment_date,
                    })),
                  },
                  errorCallback,
                });

              setIsSubmittingForm(false);
              successCallback(activationType);
            },
            errorCallback: (res) => {
              setIsSubmittingForm(false);
              errorCallback({ ...res, customParams: { aoFormData } });
            },
          });
        }
      }
    },
    [aoFormData, listSwitch, status, listRequired, errorsList, getErrors],
  );

  useEffect(() => {
    if (!lodash.isEmpty(activationOrderData) && status === statusParamsEnum.EDIT) {
      let result = {} as typeof initialOrderCardsData;
      Object.keys(initialOrderCardsData).forEach((key) => {
        if (activationOrderData[key]) {
          result = { ...result, [key]: activationOrderData[key] };
        }
      });

      if (schedulesFutureReceivablesData.records?.length > 0) {
        const cloneOldSchedule = schedulesFutureReceivablesData?.records.map((item) => ({
          ...item,
        }));
        result = {
          ...result,
          [KeyCardsEnum.PAYMENT_PLAN]: cloneOldSchedule,
        };
      }

      if (activationOrderData?.ao_document) {
        result = { ...result, [KeyCardsEnum.DROPZONE]: [new File([], activationOrderData.ao_document.file_name)] };
      }

      if (result?.company_id) {
        getContracts({ id: result.company_id });
      }
      let switchObj = {} as typeof listSwitch;
      Object.keys(listSwitch).forEach((key) => {
        if (Array.isArray(result[key]) && result[key].length > 0) {
          switchObj = { ...switchObj, [key]: true };

          return;
        }

        if (result[key] && !Array.isArray(result[key])) {
          switchObj = { ...switchObj, [key]: true };
        }
      });

      if (!lodash.isEmpty(switchObj)) {
        switchObj.custom_settings = true;
      }
      setIsSwitchList({ ...listSwitch, ...switchObj });
      setAOFormData({ ...aoFormData, ...result });
    }
  }, [activationOrderData, initialOrderCardsData, schedulesFutureReceivablesData]);

  useEffect(() => {
    if (status === statusParamsEnum.EDIT && activationOrderId) {
      (type === AoTypeEnum.CLASSIC || type === AoTypeEnum.APPLAUD_FIX) && getActivationOrder({ id: activationOrderId });
      type === AoTypeEnum.AVAILABLE_RECEIVABLES && getAvailableReceivables({ id: activationOrderId });
      type === AoTypeEnum.FUTURE_RECEIVABLES &&
        getFutureReceivables({
          id: activationOrderId,
          successCallback: async () => {
            await getScheduleForFutureReceivables({ id: activationOrderId });
          },
        });
    }

    if (type) {
      setAOFormData({ ...aoFormData, [KeyCardsEnum.AO_TYPE]: type });
    }
    getCompanies({ showDeleted: false });

    getFactorCompany()
      .then((result) => {
        const newResult =
          result[CompanyKeyEnum.BANK_DETAILS]
            ?.filter((bankAccount: BankAccount) => bankAccount.master)
            .map((bankAccount: BankAccount) => ({
              [CompanyKeyEnum.BANK_NAME]: bankAccount[CompanyKeyEnum.BANK_NAME] as string,
              [CompanyKeyEnum.SWIFT]: bankAccount[CompanyKeyEnum.SWIFT] as string,
              [CompanyKeyEnum.CURRENCY]: bankAccount[CompanyKeyEnum.CURRENCY] as string,
              [CompanyKeyEnum.ID]: result[CompanyKeyEnum.ID] as string,
              [CompanyKeyEnum.NAME]: result[CompanyKeyEnum.NAME] as string,
            })) || [];

        setFactorAccounts(newResult);
      })
      .catch(console.error);

    return () => {
      clearActivationOrder();
      clearContractsData();
      changeErrors({});
      setIsSwitchList(switchListInitial);
      setAOFormData({ ...initialOrderCardsData, [KeyCardsEnum.DISTRIBUTION_PLATFORMS]: [initialDistributionPlatform] });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const title = `AO ${activationOrderData.name} ${activationOrderData.company_name} ${
    activationOrderData.currency ? getCurrencyWithAmount(activationOrderData.amount, activationOrderData.currency) : ''
  }`;

  const getSaveDisable = () => {
    console.log('--->', aoFormData.ao_type);

    if (
      aoFormData.ao_type === AoTypeEnum.FUTURE_RECEIVABLES &&
      (!aoFormData[KeyCardsEnum.PAYMENT_PLAN]?.[0]?.amount || !aoFormData[KeyCardsEnum.PAYMENT_PLAN]?.[0]?.payment_date)
    )
      return true;

    if (
      aoFormData.ao_type === AoTypeEnum.AVAILABLE_RECEIVABLES &&
      (aoFormData?.distribution_platforms.some(
        (el) =>
          el.activation_date?.length === 0 ||
          el.currency?.length === 0 ||
          el.platform_id?.length === 0 ||
          el.purchase_percentage?.length === 0 ||
          el.purchase_price_percentage?.length === 0,
      ) ||
        ((aoFormData?.mfa_usd === null || aoFormData.mfa_usd?.toString()?.length === 0) &&
          (aoFormData?.mfa_eur === null || aoFormData?.mfa_eur?.toString()?.length === 0)))
    ) {
      return true;
    }

    return isPending;
  };

  console.log(aoFormData.ao_type === 'available_receivables');

  return (
    <form style={{ maxWidth: 608 }}>
      {status === statusParamsEnum.EDIT && (
        <ModalConfirm
          closeFc={() => setIsShowModal(false)}
          open={isShowModal}
          wrap={{
            width: 444,
            height: 199,
          }}
          leftCallback={() => {
            onSubmit(true);
          }}
          rightCallback={() => {
            setIsShowModal(false);
          }}
          leftButtonStyle={{ width: 137, boxShadow: 'none' }}
          leftButtonText={t('saveChanges')}
          rightButtonText={t('global.cancelC')}
          title={t('forms.activationOrder.afterSavingWillCalculations')}
        />
      )}
      <CreateTransactionPopUp
        isOpen={listIsOpen[KeyCardsEnum.TX_DATE]}
        onClose={() => setListIsOpen({ ...listIsOpen, [KeyCardsEnum.TX_DATE]: false })}
        style={{ width: 720 }}
        isSubmittingForm={isSubmittingForm}
        leftCallback={() => {
          if (!lodash.isEmpty(errors)) return;

          onSubmit(true, true);
        }}
        rightCallback={() => successCallback({ name: t('forms.activationOrder.aoStatusCantBeChanged') })}
      />
      <Title
        text={status === statusParamsEnum.EDIT ? title : `${t('forms.activationOrder.createActivationOrder')}`}
        aoType={status === statusParamsEnum.EDIT ? aoFormData.ao_type : undefined}
      />
      {status === statusParamsEnum.EDIT && (
        <ItemsAo
          types={[
            KeyCardsEnum.STATUS,
            aoFormData.status === StatusEnumAo.REPAID ? KeyCardsEnum.CLOSING_DATE : null,
            aoFormData.ao_type === 'available_receivables' ? KeyCardsEnum.NAME : '',
          ]}
        />
      )}
      {aoFormData.ao_type === AoTypeEnum.CLASSIC && <CardClassic params={params} />}
      {aoFormData.ao_type === AoTypeEnum.APPLAUD_FIX && <CardApplaud params={params} />}
      {/* {aoFormData.ao_type === AoTypeEnum.AVAILABLE_RECEIVABLES && <CardAvailableReceivables params={params} />} */}
      {aoFormData.ao_type === AoTypeEnum.AVAILABLE_RECEIVABLES && <CardNow params={params} />}
      {aoFormData.ao_type === AoTypeEnum.FUTURE_RECEIVABLES && <CardFutureReceivables params={params} />}

      <div style={{ margin: '40px 0 24px 0' }}>
        <Button
          type="button"
          onClick={() => onSubmit()}
          style={{ height: 40, width: 198, marginRight: 24 }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...stylingButton}
          disabled={getSaveDisable()}
        >
          {status === statusParamsEnum.EDIT ? t('global.saveChanges') : t('global.saveS')}
        </Button>
        <Button
          style={{ height: 40, width: 89 }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...dropZoneStylingButton}
          onClick={() => successCallback({ name: 'Cancel' })}
        >
          {t('global.cancelC')}
        </Button>
      </div>
    </form>
  );
});

export default ActivationOrderForm;
